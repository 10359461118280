<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
                <b-overlay :show="loadingState">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationProvider :name="$t('research_test.schedule_date')" vid="schedule_date" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="schedule_date"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_test.schedule_date')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            class="form-control datepicker"
                                        id="schedule_date"
                                        v-model="schedule.date"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="12" sm="12">
                                    <b-form-group class="row" label-cols-sm="4" label-for="remarks" >
                                        <template v-slot:label>
                                            {{$t('research_test.remarks')}}
                                        </template>
                                        <b-form-textarea
                                            plain
                                            id="remarks"
                                            v-model="schedule.remarks"
                                        >
                                        </b-form-textarea>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '../../../../../config/api_config'
import { testingTagRequestWithoutCetificationApprove } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  name: 'FormLayout',
  props: ['items'],
  mixins: [ModalBaseMasterList],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
        saveBtnName: this.$t('globalTrans.save'),
        schedule: {
            id: null,
            general_info_id: '',
            date: null,
            remarks: ''
        }
    }
  },
  computed: {
  },
    watch: {
    },
  created () {
      this.schedule.id = this.items.id
      this.schedule.date = this.items.date_of_collection
      this.schedule.remarks = this.items.remarks
  },
  mounted () {
      core.index()
      flatpickr('.datepicker', {})
  },
  methods: {
    async saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      this.schedule.general_info_id = this.items.id
      const result = await RestApi.postData(agriResearchServiceBaseUrl, testingTagRequestWithoutCetificationApprove, this.schedule)
      if (result.success) {
        // const notification = result.notification
        // this.$socket.emit('send-notification', notification)
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        this.$bvModal.hide('modal-4')
          this.$emit('listenerChild')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
